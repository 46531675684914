import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Accessibility = () => (
  <>
    <Layout
      headerComponent={
        <header className="header pt-8 pb-4 bg-dark text-white text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <h1>Accessibility</h1>
                {/* <p className="lead-2 opacity-90 mt-6">
                Last update: May 20, 2021
              </p> */}
              </div>
            </div>
          </div>
        </header>
      }
    >
      <section className="section py-7">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <p className="lead-3">
                Nextate Credit, LLC, doing business as Trustic, and its
                affiliates (collectively, "Trustic") are committed to ensuring
                that its website,{" "}
                <a href="http://trustic.com">http://trustic.com</a> (the
                "Website"), is accessible to and usable by all persons,
                including persons with disabilities. To accomplish this, Trustic
                has adopted a website accessibility policy that demonstrates its
                commitment to the accessibility of its Website. In addition, it
                is actively taking steps to enhance its Website so that
                individuals with disabilities are not excluded, denied services,
                segregated, or otherwise treated differently because of their
                disability, where such steps are readily achievable, thereby
                ensuring that persons with disabilities have a full and equal
                opportunity to benefit from the products, services, facilities,
                privileges, advantages, and accommodations offered by Trustic
                throughout its Website.
              </p>
              <p className="lead-3">
                Should you have any accessibility questions or concerns, please
                do not hesitate to contact Trustic at{" "}
                <a href="mailto:accessibility@trustic.com">
                  accessibility@trustic.com
                </a>
                . Please be aware that our efforts to provide an accessible
                Website are ongoing. If you do encounter an accessibility issue,
                please be sure to specify the web page at issue in your email or
                communication to us, and we will make reasonable efforts to
                address your concern.
              </p>
              <p className="lead-3">
                Trustic is committed to making its Website accessible for all of
                its valued customers.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
    <Seo
      title="Trustic | Accessibility"
      description="Your questions answered about guarantor personal loans from Trustic."
    />
  </>
)

export default Accessibility
